// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.6.0",
  "buildNumber": "12005",
  "gitSha": "12860aac6351e2a353728c7d7913f34d741c63e0",
  "fullVersion": "20.6.0-12005",
  "formattedVersion": "20.6.0 (12005-12860aac6351e2a353728c7d7913f34d741c63e0)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.6.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.6.0/${stripLeadingPrefix(suffix, '#')}`
}
    
